import React, { useState, useEffect, useContext } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import AppContext from "../contexts/app/context"

export default function product({ pageContext: { book: productData } }) {
  const {
    name,
    image,
    price,
    description,
    summary,
    category,
    author,
    _id,
  } = productData
  // const { GATSBY_API_HOST } = process.env
  const GATSBY_API_HOST = "https://hamzatanjicherkaoui.com/api"
  const [clientToken, setClientToken] = useState(null)
  const { addProductToCart } = useContext(AppContext)

  useEffect(() => {
    ;(async () => {
      const response = await (
        await fetch(`${GATSBY_API_HOST}/paypal/generate-client-token`)
      ).json()
      setClientToken(response?.client_token || response?.clientToken)
    })()
  }, [])
  return (
    <>
      <section>
        <div className="relative mx-auto max-w-screen-xl px-4 py-8">
          <div className="grid gap-8 lg:grid-cols-4 lg:items-start">
            <div className="lg:col-span-3">
              <h1 className="text-xl font-bold pt-8 mb-12">{name}</h1>
              <div className="relative mt-4 flex justify-center">
                <img
                  alt="Tee"
                  src={`${GATSBY_API_HOST}/download/${image}`}
                  className="h-72 w-1/2 rounded-xl object-cover lg:h-[540px]"
                />
              </div>
            </div>

            <div className="lg:sticky lg:top-0">
              <div className="space-y-4 lg:pt-8">
                <div className="rounded  p-4">
                  <p className="text-sm">
                    <span className="block mb-6">
                      {" "}
                      <span className="font-bold text-lg mr-2 text-purple-800">
                        Category:
                      </span>{" "}
                      <span className="text-gray-600"> {category}</span>
                    </span>
                    <span className="block mb-6">
                      {" "}
                      <span className="font-bold text-lg mr-2 text-purple-800">
                        Author:
                      </span>{" "}
                      <span className="text-gray-600"> {author}</span>
                    </span>
                    <span className="block mb-6">
                      {" "}
                      <span className="font-bold text-lg mr-2 text-purple-800">
                        small description:{" "}
                      </span>{" "}
                      <span className="text-gray-600">{description}</span>
                    </span>
                  </p>
                </div>

                <div>
                  <p className="text-4xl text-purple-900 font-bold text-center">
                    {price} $
                  </p>
                </div>
                {!clientToken && (
                  <div className=" py-12 px-6  bg-gray-100 rounded-2xl text-sm text-center text-purple-600 font-light animate-pulse">
                    <p>Loading Payment Methods ...</p>
                  </div>
                )}
                {clientToken && (
                  <PayPalScriptProvider
                    options={{
                      clientId: process.env.GATSBY_CLIENT_ID,
                      components: "buttons,hosted-fields",
                      intent: "capture",
                      vault: false,
                      dataClientToken: clientToken,
                    }}
                  >
                    {/* return fetch(GATSBY_API_HOST+"/orders", { */}
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={async (data, actions) => {
                        try {
                          const response = await fetch(
                            `${GATSBY_API_HOST}/paypal/orders`,

                            {
                              body: JSON.stringify({
                                type: "single",
                                productId: _id,
                              }),
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          )

                          const details = await response.json()
                          return details.id
                        } catch (error) {
                          console.error(error)
                          // Handle the error or display an appropriate error message to the user
                        }
                      }}
                      onApprove={async (data, actions) => {
                        try {
                          const response = await fetch(
                            `${GATSBY_API_HOST}/paypal/orders/${data.orderID}/capture`,
                            {
                              method: "POST",
                            }
                          )

                          const details = await response.json()
                          // Three cases to handle:
                          //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                          //   (2) Other non-recoverable errors -> Show a failure message
                          //   (3) Successful transaction -> Show confirmation or thank you message

                          // This example reads a v2/checkout/orders capture response, propagated from the server
                          // You could use a different API or structure for your 'orderData'
                          const errorDetail =
                            Array.isArray(details.details) && details.details[0]

                          if (
                            errorDetail &&
                            errorDetail.issue === "INSTRUMENT_DECLINED"
                          ) {
                            return actions.restart()
                            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                          }

                          if (errorDetail) {
                            let msg =
                              "Sorry, your transaction could not be processed."
                            msg += errorDetail.description
                              ? ` ${errorDetail.description}`
                              : ""
                            msg += details.debug_id
                              ? ` (${details.debug_id})`
                              : ""
                            alert(msg)
                          }

                          // Successful capture! For demo purposes:
                          console.log(
                            "Capture result",
                            details,
                            JSON.stringify(details, null, 2)
                          )
                          const transaction =
                            details.purchase_units[0].payments.captures[0]
                          alert(
                            `Transaction ${transaction.status}: ${transaction.id}See console for all available details`
                          )
                        } catch (error) {
                          console.error(error)
                          // Handle the error or display an appropriate error message to the user
                        }
                      }}
                    />
                  </PayPalScriptProvider>
                )}
                <button
                  onClick={() => {
                    addProductToCart(productData)
                  }}
                  className="w-full rounded-3xl bg-purple-600 px-6 py-3 text-sm font-bold uppercase tracking-wide text-white"
                >
                  Add To Cart
                </button>
              </div>
            </div>

            <div className="lg:col-span-3">
              <div className="prose max-w-none">
                <h2 className="text-2xl font-semibold text-purple-800 pb-4 mb-4 border-b border-purple-200">
                  {" "}
                  Book Summary
                </h2>
                <p>{summary}</p>
                <div className="mt-12 mb-12">
                  <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-purple-800 mb-4">
                      Customer Reviews
                    </h2>
                    <div className="border-b border-purple-300" />
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                          alt="Customer 1"
                        />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-semibold text-purple-800">
                          John Doe
                        </h3>
                        <p className="text-gray-600">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Mauris rhoncus diam non ex tristique, a congue
                          enim facilisis.
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                          alt="Customer 2"
                        />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-semibold text-purple-800">
                          Jane Smith
                        </h3>
                        <p className="text-gray-600">
                          Nulla tincidunt fringilla tellus, ut commodo lacus
                          consectetur a. Vestibulum ante ipsum primis in
                          faucibus orci luctus et ultrices posuere cubilia
                          Curae; Nunc euismod nisl id varius pretium.
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                          alt="Customer 3"
                        />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-semibold text-purple-800">
                          Emily Johnson
                        </h3>
                        <p className="text-gray-600">
                          Fusce eu sagittis elit, et tempus nunc. Integer vitae
                          ligula augue. Etiam nec urna id felis vestibulum
                          interdum ac ac risus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export function Head() {
  return (
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
  )
}
